.App {
  text-align: center;
}

.video-upload-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.video-upload-form {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.video-upload-form > * {
  margin: 10px;
}
